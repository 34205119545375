<template>
  <div class="masthead-wrapper">
    <div class="container masthead" style="display: flex">
      <div class="logo" style="float: left"></div>
      <div class="logo_title" style="float: left">Order Tracking</div>
      <div class="menu" style="margin-left: 750px">
        <div
          class="login"
          id="btnLogin"
          style="margin-top: 0px; width: 99px; margin-left: 30px"
          v-on:click="btnLoginClick"
          v-if="ifGuid == false"
        >
          <div>Log In</div>
        </div>
        <div
          class="login"
          id="btnLogout"
          style="margin-top: -5px; display: none"
          v-on:click="btnLogoutClick"
          v-if="ifGuid == false"
        >
          <div
            style="
              padding: 5px 10px;
              border-radius: 20px;
              border: white 1px solid;
            "
          >
            Log Out
          </div>
        </div>

        <span class="btnMenuItem">FAQs</span>
        <span class="btnMenuItem">0800 426 473</span>
      </div>

      <div style="display: none" class="emailAddr">{{ session.email }}</div>
    </div>
  </div>
  <div v-if="session.token.length>1 && configs && configs.length>0"  class="container-main"
    style="
      display: flex;
      align-items: center;
      justify-content: left;
      width: 1400px;
      padding: 5px;
    ">
    <h3 style="">Configurations:</h3>
  </div>
  
  <div
    v-if="session.token.length>1 && configs && configs.length>0" 
    class="container-main"
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1400px;
      padding: 30px;
    "
  >
    <div>
      <div class="table-responsive" style="">
        <table id="dtConfigs" class="table table-striped">
          <thead>
            <tr role="row">
              <th>config_id</th>
              <th>config_type</th>
              <th>config_description</th>
              <!-- <th>config_description</th> -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="config in configs" v-bind:key="config.config_id">
              <td>{{ config.config_id }}</td>
              <td>{{ config.config_type }}</td>
              <td>{{ config.config_description }}</td>
              <!-- <td>{{ config.config_description }}</td> -->
              <td><a v-if="config.ui_modifiable=='true'" v-bind:href="'/admin/config/'+config.config_id" class="btnEdit">EDIT</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import admin from "./../../public/scripts/admin.js";

export default {
  mixins: [admin],
  // ... other component specific logic
};
</script>

<style scoped>
@import "../../public/stylesheets/style_desktop.css";
@import "../../public/stylesheets/style_admin.css";
</style>
