<template>
  <div class="masthead-wrapper">
    <div class="container masthead" style="display: flex">
      <div class="logo" style="float: left"></div>
      <div class="logo_title" style="float: left">Order Tracking</div>
      <div class="menu" style="margin-left: 750px">
        <div
          class="login"
          id="btnLogin"
          style="margin-top: 0px; width: 99px; margin-left: 30px"
          v-on:click="btnLoginClick"
          v-if="ifGuid == false"
        >
          <div>Log In</div>
        </div>
        <div
          class="login"
          id="btnLogout"
          style="margin-top: -5px; display: none"
          v-on:click="btnLogoutClick"
          v-if="ifGuid == false"
        >
          <div
            style="
              padding: 5px 10px;
              border-radius: 20px;
              border: white 1px solid;
            "
          >
            Log Out
          </div>
        </div>

        <span class="btnMenuItem">FAQs</span>
        <span class="btnMenuItem">0800 426 473</span>
      </div>

      <div style="display: none" class="emailAddr">{{ session.email }}</div>
    </div>
  </div>
  <div v-if="session.token.length>1" >
    <div v-if="config_data && config_data.ui_modifiable=='true'" class="container">
      <div>
        <h4>Config Edit</h4>
        <div style="display: none;width:940px;" id="search-alert"><div class="img"></div><p></p></div>
        <div style="font-size: 13px;width: 1700px;margin-left: 10px;">
          <div style="width: 100%;display: flex;justify-items: left;">
            <span style="font-weight: bold;margin-right: 10px;margin-bottom: 10px;">Config ID: </span
            >{{ config_data.config_id }}
          </div>
          <div style="width: 100%;display: flex;justify-items: left;">
            <span style="font-weight: bold;margin-right: 10px;margin-bottom: 10px;">Config Type: </span
            >{{ config_data.config_type }}
          </div>
          <div style="width: 100%;display: flex;justify-items: left;">
            <span style="font-weight: bold;margin-right: 10px;margin-bottom: 10px;">Config Description: </span
            >{{ config_data.config_description }}
          </div>
        </div>

        <div>
          <textarea
            id="config_value"
            name="config_value"
            type="text"
            class="form-control"
            v-model="config_data.config_value"
            style="width: 1700px; height: 350px; font-size: 12px"
          />
        </div>
      </div>

      <div style="margin-top: 20px; color: white !important;display: flex;align-items: center;justify-content: center;">
        <div class="btnOrder" style="display: flex;align-items: center;justify-content: center;cursor: pointer;" v-on:click="save()">Save</div>
        <div class="btnOrder" style="display: flex;align-items: center;justify-content: center;cursor: pointer;" v-on:click="back()">Back</div>
      </div>
    </div>
  </div>
</template>
<script>
import adminEdit from "./../../public/scripts/adminEdit.js";

export default {
  mixins: [adminEdit],
  // ... other component specific logic
};
</script>

<style scoped>
@import "../../public/stylesheets/style_desktop.css";
@import "../../public/stylesheets/style_admin.css";
</style>
