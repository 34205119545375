<template>  
  <router-view/>
</template>

<style>

@import'~bootstrap/dist/css/bootstrap.css';
/* @import'~jquery-ui-bundle/jquery-ui.css'; */
@import'~datatables.net-dt/css/jquery.dataTables.css';


</style>
