import $ from "jquery";

export default {
  data() {
    return {      
      hasError: false,
      isMounted: false,  
      faqs:[],
    //   crisis_comms:'Due to extreme weather events on the upper North Island, please expect delays with some of your order!',
      crisis_comms:null,    
    };
  },
  computed: {},
  created: function () {
    
        this.load();

  },
  mounted: async function () {
    // await this.load();
    await this.gtag();
  },

  methods: {
    load() {
      $('#overlay').css('display','block');
      var url = process.env.VUE_APP_FE_URL;
      console.log("be url:" + url);

      let fullUrl = url + "/int/v1.0/faqs";

      fetch(fullUrl)
        .then((response) => response.json())
        .then((res) => {
          this.faqs = res.faq_list;
          this.crisis_comms = res.crisis_comms;
          $('#overlay').css('display','none');
        });
    },
    handleSubmit() {
      console.log("prevent form submit");
    },

    async gtag() {
      if (
        process.env.VUE_APP_FE_URL.indexOf("ordertracking.officemax.co.nz") !=
        -1
      ) {
        const gtmScriptTag = `<script async src="https://www.googletagmanager.com/gtag/js?id=G-1MJR8PWZ36"></script>`;
        const gtmInlineScript = `
              <script>
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-1MJR8PWZ36');
              </script>`;

        $("head").append(gtmScriptTag);
        $("head").append(gtmInlineScript);
      }
    },

    btnLoginClick() {
      console.log("redirect to login");
      window.location.href = window.location.origin + "/azure-login";
    },
    btnLogoutClick() {
      fetch("/clear-session")
        .then((response) => response.json())
        .then((res) => {
          if (res.result == true) {
            window.location.href = "/";
          }
        });
    },
    back(){
        window.location.href = '/admin';
    },
    
    showMessage(message,level){

        $('#search-alert p').html(message);
        $('#search-alert').removeClass('alert-warn');
        $('#search-alert').removeClass('alert-info');
        if(level == 'warn'){
            $('#search-alert').addClass('alert-warn');
        }
        else{
            $('#search-alert').addClass('alert-info');
        }
        
        $('#search-alert').show();
    },
    answerToggle:function(event){
        event.preventDefault();
        var element = event.currentTarget;
        if($(element).hasClass('answer_collapse')){
            $(element).parent().parent().find('.faq_answer').slideDown();
            $(element).removeClass('answer_collapse');
            $(element).addClass('answer_expand');
        }
        else{
            $(element).parent().parent().find('.faq_answer').slideUp();
            $(element).removeClass('answer_expand');
            $(element).addClass('answer_collapse');
        }
        
    },
    contentToggle:function(event){
        event.preventDefault();
        var element = $(event.currentTarget).siblings()[1];
        if($(element).hasClass('answer_collapse')){
            $(element).parent().parent().find('.faq_answer').slideDown();
            $(element).removeClass('answer_collapse');
            $(element).addClass('answer_expand');
        }
        else{
            $(element).parent().parent().find('.faq_answer').slideUp();
            $(element).removeClass('answer_expand');
            $(element).addClass('answer_collapse');
        }
    }
  },
};
