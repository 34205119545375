import $ from "jquery";

export default {
  data() {
    return {
      session: {
        email: "",
        token: "",
      },
      hasError: false,
      inputFocused: false,
      isMounted: false,
      config_data: {
        config_id: "",
        config_type: "",
        config_value: "",
      },
    };
  },
  computed: {},
  created: function () {
    console.log("mixin created: guid" + this.guid);

    fetch("/get-session-all")
      .then((response) => response.json())
      .then((data) => {
        console.log("got email and token from remote session.");
        if (data.email) {
          this.session.email = data.email;
          this.session.token = data.token;
          $("#btnLogin").hide();
          $("#btnLogout").show();
        } else {
          $("#btnLogin").show();
          $("#btnLogout").hide();
        }
      }).then(()=>{
        this.load();
      });
  },
  mounted: async function () {
    // await this.load();
  },

  methods: {
    load() {
      var url = process.env.VUE_APP_FE_URL;
      console.log("be url:" + url);

      var cid = window.location.pathname.split("/")[3];
      let fullUrl = url + "/int/v1.0/config/" + cid;

      fetch(fullUrl, {
        headers: {
          Authorization: "Bearer " + this.session.token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          this.config_data = res;
        });
    },
    handleSubmit() {
      console.log("prevent form submit");
    },

    btnLoginClick() {
      console.log("redirect to login");
      window.location.href = window.location.origin + "/azure-login";
    },
    btnLogoutClick() {
      fetch("/clear-session")
        .then((response) => response.json())
        .then((res) => {
          if (res.result == true) {
            window.location.href = "/";
          }
        });
    },
    back(){
        window.location.href = '/admin';
    },
    save() {
      var objToSave = {};
      objToSave.id = this.config_data.config_id;
      objToSave.value = this.config_data.config_value;

      var requestOptions = {
        method: "POST",
        headers: { 
          "Content-Type": "application/json" ,
          Authorization: "Bearer " + this.session.token,        
        },        
        body: JSON.stringify(objToSave),
      };

      let url = process.env.VUE_APP_FE_URL;
      let full_url = url + "/int/v1.0/config/update";

      fetch(full_url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (!data) {
            this.showMessage("Failed.", "warn");
          } else {
            this.showMessage("Successfully.", "info");
          }
        });
    },
    showMessage(message,level){

        $('#search-alert p').html(message);
        $('#search-alert').removeClass('alert-warn');
        $('#search-alert').removeClass('alert-info');
        if(level == 'warn'){
            $('#search-alert').addClass('alert-warn');
        }
        else{
            $('#search-alert').addClass('alert-info');
        }
        
        $('#search-alert').show();
    },
  },
};
