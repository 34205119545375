<template>
  <div
    class="masthead-wrapper"
    style="
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <div
      class="masthead"
      style="
        display: flex;
        min-width: 360px;
        align-items: center;
        justify-content: left;
      "
    >
      <div
        class="logo-mobile"
        style="height: 21px; margin: 0px 30px 0px 0px"
      ></div>
      <div
        class="logo_title-mobile"
        style="
          height: 21px;
          margin: 0px;
          min-width: 160px;
          padding-top: 4px;
          text-align: left;
        "
      >
        Order Tracking
      </div>
      <a href="tel:0800426473">
        <img
          style="width: 25px; height: 25px; float: right"
          src="../../public/images/phone.png"
        />
      </a>      
      <img
        style="width: 25px; height: 25px; float: right;margin-left: 10px;"
        src="../../public/images/question_orange.png"
      />
    </div>
  </div>
  <div style="display: flex; align-items: center; justify-content: center">
    <div
      style="
        width: 360px;
        font-size: 36px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: left;
        margin-top: 15px;
      "
    >
      <span style="text-align: left; font-size: 20px; font-weight: 400"
        >Frequently Asked Questions</span
      >
    </div>
  </div>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
    "
  >
    <div class="alertArea-mobile" style="width: 360px;margin-left: 0px;margin-top: 0px;height: auto;display: flex;" v-if="crisis_comms != null">
      <img src="../../public/images/Subtract.svg" style="height: 14px;width:14px;align-self: baseline;margin-right: 5px;" />
      <p style="align-self: baseline;">
        {{ crisis_comms }}
      </p>
    </div>

  </div>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      margin-top: -15px;
    "
  >
    <div
      style="
        margin-top: 5px;
        text-align: left;
        background-color: white;
        border: 1px white solid;
        border-radius: 5px;
        padding: 20px 28px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
        width: 360px;
      "
      v-for="faq in faqs"
      v-bind:key="faq.section"
    >
      <p class="faq_title" style="font-size: 16px; font-weight: 400">
        {{ faq.section }}
      </p>
      <div v-for="pair in faq.body" v-bind:key="pair.question">
        <p
          class="faq_question"
          style="width: 300px; font-size: 12px; font-weight: 400"
        >
          <span class="questionCircle">Q</span>
          <span style="width: 90%">{{ pair.question }}</span>
          <span class="answer_collapse" v-on:click="answerToggle"></span>
        </p>
        <p
          class="faq_answer"
          style="width: 300px; display: none; font-size: 11px; font-weight: 400"
        >
          <span class="answerCircle" style="align-self: baseline;">A</span>
          <span style="width: 270px">
            {{ pair.answer }}
          </span>
        </p>
      </div>
    </div>
  </div>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
    "
  >
    <div
      class="btn-primary btnOrder"
      style="display: flex; align-items: center; justify-content: center;margin: 0px !important;"
    >
      <a href="/">Back to Home</a>
    </div>
  </div>
  <!-- Footer -->
  <footer
    id="footer"
    class="text-center text-lg-start bg-light text-muted"
    
  >
    <div class="row" style="margin-left:0px;margin-right:0px;">
      <div style="margin-top: 10px">
        <div
          style="
            margin-left: 10px;
            width: 300px;
            text-align: left;
            justify-content: left;
          "
        >
          <div class="mb-2">Other OfficeMax Websites</div>
          <div><a href="https://www.officemax.co.nz">OfficeMax</a></div>
          <div><a href="https://www.myschool.co.nz">MySchool</a></div>
            <!-- <div><a href="#">Careers at OfficeMax</a></div> -->
        </div>
        <div
          style="
            margin-left: 10px;
            width: 300px;
            margin-top: 30px;
            text-align: left;
            justify-content: left;
          "
        >
          <div class="mb-2">Customer Support</div>
          <div><a href="/faq">Frequent Asked Questions</a></div>
          <div><a href="#">0800 426 473</a></div>
        </div>
        <div
          style="
            padding-bottom: 20px;
            width: 360px;
            text-align: left;
            justify-content: left;
          "
        >
          <div class="logo" style="margin-left: 10px"></div>
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer -->
  <div id="overlay" style="display: none;">
    <img src="../../public/images/gear-wide.svg" alt="Loading..." />
  </div>
</template>
<script>
import faq from "./../../public/scripts/faq.js";

export default {
  mixins: [faq],
  // ... other component specific logic
};
</script>

<style scoped>
@import "../../public/stylesheets/style_desktop.css";
</style>
