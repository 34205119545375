<template>
  <div
    class="masthead-wrapper"
    style="
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <div
      class="masthead"
      style="
        display: flex;
        min-width: 960px;
        align-items: center;
        justify-content: left;
      "
    >
      <div class="logo" style="height: 43px; margin: 0px 30px 0px 0px"></div>
      <div
        class="logo_title"
        style="
          height: 43px;
          margin: 0px;
          min-width: 560px;
          padding-top: 4px;
          text-align: left;
        "
      >
        Order Tracking
      </div>
      <div
        class="btnMenuItem"
        style="float: right; font-size: 14px; font-weight: 500"
      >
        0800 426 473
      </div>
      <div
        class="btnMenuItem"
        style="float: right; font-size: 14px; font-weight: 700"
      >
        <a href="/faq" style="text-decoration: none; color: #f7911d">FAQs</a>
      </div>
    </div>
  </div>
  <div style="display: flex; align-items: center; justify-content: center">
    <div
      style="
        width: 960px;
        font-size: 36px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: left;
        margin-top: 68px;
      "
    >
      <span style="width: 72%; text-align: left"
        >Frequently Asked Questions</span
      >
      <div>
        <div class="alertArea" style="width: 445px" v-if="crisis_comms != null">
          <img src="../../public/images/Subtract.svg" />
          <p style="padding: 10px; margin-top: -30px; margin-left: 20px">
            {{ crisis_comms }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
    "
  >
    <div
      style="
        margin-top: 20px;
        text-align: left;
        background-color: white;
        border: 1px white solid;
        border-radius: 5px;
        padding: 20px 50px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
        width: 960px;
      "
      v-for="faq in faqs"
      v-bind:key="faq.section"
    >
      <p class="faq_title">{{ faq.section }}</p>
      <div v-for="pair in faq.body" v-bind:key="pair.question">
        <p class="faq_question" style="width: 885px">
          <span class="questionCircle">Q</span>
          <span class="question_content" style="width: 90%" v-on:click="contentToggle">{{ pair.question }}</span>
          <span class="answer_collapse" v-on:click="answerToggle"></span>
        </p>
        <p class="faq_answer" style="width: 885px; display: none">
          <span class="answerCircle">A</span>
          <span style="width: 98%">
            {{ pair.answer }}
          </span>
        </p>
      </div>
    </div>
  </div>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
    "
  >
    <div
      class="btn-primary btnOrder"
      style="display: flex; align-items: center; justify-content: center"
    >
      <a href="/">Back to Home</a>
    </div>
  </div>
  <footer id="footer" class="text-center text-lg-start bg-light text-muted">
    <div class="row">
      <div class="d-flex" style="margin-top: 30px">
        <div style="margin-left: 190px">
          <div class="mb-2 font-weight-bold bold">Other OfficeMax Websites</div>
          <div><a href="https://www.officemax.co.nz">OfficeMax</a></div>
          <div><a href="https://www.myschool.co.nz">MySchool</a></div>
          <!-- <div><a href="#">Careers at OfficeMax</a></div> -->
        </div>
        <div style="margin-left: 190px">
          <div class="mb-2 font-weight-bold bold">Customer Support</div>
          <div><a href="/faq">Frequent Asked Questions</a></div>
          <div><a href="#">0800 426 473</a></div>
        </div>
      </div>
    </div>
    <div
      class="row justify-content-end"
      style="padding-right: 190px; padding-bottom: 50px"
    >
      <div class="logo"></div>
    </div>
  </footer>
</template>
<script>
import faq from "./../../public/scripts/faq.js";

export default {
  mixins: [faq],
  // ... other component specific logic
};
</script>

<style scoped>
@import "../../public/stylesheets/style_desktop.css";
</style>
