import $ from "jquery";

export default {
  props: {
    guid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      session: {
        email: "",
        token: "",
      },
      hasError: false,
      inputFocused: false,
      isMounted: false,
      configs: [],
    };
  },
  computed: {},
  created: async function () {
    console.log("mixin created: guid" + this.guid);

    fetch("/get-session-all")
      .then((response) => response.json())
      .then((data) => {
        console.log("got email and token from remote session.");
        if (data.email) {
          this.session.email = data.email;
          this.session.token = data.token;
          $("#btnLogin").hide();
          $("#btnLogout").show();
        } else {
          $("#btnLogin").show();
          $("#btnLogout").hide();
        }
        
      }).then(()=>{
        this.load();
      });
  },
  mounted: async function () {
    // await this.load();
  },

  methods: {
    load() {
      var url = process.env.VUE_APP_FE_URL;
      console.log("be url:" + url);

      let fullUrl = url + "/int/v1.0/config/list";

      fetch(fullUrl, {
        headers: {
          Authorization: "Bearer " + this.session.token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          this.configs = res.Configs;
          $('#dtConfigs').css('display','table')
        })
        .then(() => {
          $("#dtConfigs").dataTable({
            paging: true,
            pageLength: 10,
            // dom: "Bfrtip",
            // buttons: ["csv", "excel", "pdf", "print"],
          });
          
          // $('#dtConfigs tbody tr').off('mouseenter').on('mouseenter',function(){
          //   $(this).find('td').css('background-color','#e3f7ff !important');
          //   $(this).find('td').css('color','#007fc7 !important');
          // });
        });
    },
    handleSubmit() {
      console.log("prevent form submit");
    },

    btnLoginClick() {
      console.log("redirect to login");
      window.location.href = window.location.origin + "/azure-login";
    },
    btnLogoutClick() {
      fetch("/clear-session")
        .then((response) => response.json())
        .then((res) => {
          if (res.result == true) {
            window.location.href = "/";
          }
        });
    },
    showMessage(message, level) {
      $("#search-alert p").html(message);
      $("#search-alert").removeClass("alert-warn");
      $("#search-alert").removeClass("alert-info");
      if (level == "warn") {
        $("#search-alert").addClass("alert-warn");
      } else {
        $("#search-alert").addClass("alert-info");
      }

      $("#search-alert").show();
      $("#overlay").hide();
    },
  },
};
