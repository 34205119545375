import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
// import "jquery-ui-bundle";
import '../public/stylesheets/style_general.css'
import '../public/scripts/common.js'
import 'datatables.net'
import 'datatables.net-dt'
import 'datatables.net-buttons'
import 'datatables.net-buttons-dt'
import 'datatables.net-buttons/js/buttons.html5.js'
createApp(App).use(router).mount('#app')
const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
link.type = 'image/x-icon';
link.rel = 'shortcut icon';
link.href = '../images/favicon.ico';
document.getElementsByTagName('head')[0].appendChild(link);
document.title = 'OfficeMax Order Track';



