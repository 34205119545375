<template>
    <div>
        <p>Env Variable:{{ env }}</p>
        <p>User Agent: {{ userAgent }}</p>
        <p>Platform: {{ platform }}</p>
        <p>Language: {{ language }}</p>
        <p>Product: {{ product }}</p>
        <p>appVersion:{{ appVersion }}</p>
    </div>
</template>
<script>

export default {
    name: 'TestView',
    components: {

    },
    data(){
            return{
                env:process.env.VUE_APP_API_URL,
                userAgent: navigator.userAgent,
                platform: navigator.platform,
                language: navigator.language,
                product: navigator.product,
                appVersion: navigator.appVersion
            }
    },
    mounted: function() {
        this.load();
    },

    methods:{        
        load(){ 
            console.log(navigator);
        },
    }
}
</script>
