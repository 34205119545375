import { VueElement } from "vue";

VueElement.common = VueElement.prototype.common = 
{
    // base_url : 'http://internal.omx.api.fdl.co.nz',
    base_url : process.env.VUE_APP_API_URL,
    getPostReqOpts: function(objToSave){
        var requestOptions = 
                {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify(objToSave)
                };
        var token = window.localStorage.getItem('token');
        requestOptions.headers['Authorization'] = ' Bearer '+token;
        return requestOptions;
    },
    getGetReqOpts: function(){
        var requestOptions = 
                {
                    method: "GET",
                    headers: {"Content-Type": "application/json"},
                    //body: JSON.stringify(objToSave)
                };
        var token = window.localStorage.getItem('token');
        requestOptions.headers['Authorization'] = ' Bearer '+token;
        return requestOptions;
    },
    dateConvert : function(dateStr){
        var date = new Date(dateStr);
    
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
    
        if(month < 10) month = '0' + month;
        if(day < 10) day = '0' + day;
    
        var formattedDate = day + '/' + month + '/' + year;
    
        return formattedDate;
    },
    timeConvert : function(dateStr){
        var date = new Date(dateStr);
    
        var hour = date.getHours();
        var minute = date.getMinutes();
        var second = date.getSeconds();
        if(hour < 10) hour = '0' + hour;
        if(minute < 10) minute = '0' + minute;
        if(second < 10) second = '0' + second;
    
        var formattedDate = hour + ':' + minute + ':' + second;
    
        return formattedDate;
    },
    boolToString: function(b) {
        return b ? 'Y' : 'N';
    }
}
;