<template>
    <div>wait...</div>
</template>
  
<script>
  import * as msal from "@azure/msal-browser";
 
  export default {
    name: 'LoginView',
    components: {

    },
    data(){
            return{
                
            }
        },
    created:function(){
                              
                      },
    mounted: async function() {
        await this.load();
    },

    methods:{        
        async load() {
                console.log('load');

                const msalConfig = {
                    auth: {
                        clientId: 'c4fa6118-36eb-4b7e-8c70-bdefc66d2b4a',
                        authority: 'https://login.microsoftonline.com/6b6f51f3-d76c-431a-92b4-7cfc05471616',
                    }
                };

                const msalInstance = new msal.PublicClientApplication(msalConfig);
                await msalInstance.initialize();
                msalInstance.handleRedirectPromise()
                    .then(handleResponse)
                    .catch(err => {
                        console.error(err);
                    });

                function handleResponse(resp) {
                    if (resp !== null) {
                        localStorage.setItem('account', resp.account.homeAccountId);
                    } else {
                        msalInstance.loginRedirect({
                            redirectUri: "http://localhost:8080/login"
                        });
                    }
                }
            },        

    }
  }
</script>

<style scoped>
    
</style>
<style>
    
</style>