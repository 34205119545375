// commonMixin.js
import $ from "jquery";
import { VueElement } from "vue";

export default {
  props: {
    guid: {
      type: String,
      required: true,
    },
    lastSearchKeywords: {
      type: String,
      required: true,
    },
    ifViewUpdated: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      statusNameMap: [
        {
          status: "OrderPlaced",
          img: "Icon_Order_Received_80Black_optimised 1",
        },
        {
          status: "OrderPacking",
          img: "Icon_Packing_Now_80Black_optimised 2",
        },
        {
          status: "ReadyToShip",
          img: "Icon_Ready_To_Ship_80Black_optimised 1",
        },
        {
          status: "OutForDelivery",
          img: "Icon_On_Its_Way_80Black_optimised 3",
        },
        {
          status: "Delivered",
          img: "Icon_Delivered_80Black_optimised 2",
        },
      ],
      orders: [],
      customer: {},
      alertInfo:
        "Due to extreme weather events on the upper North Island, please expect delays with some of your order!",
      shipInfo:
        "Please note: Your organisation may have requested not to ship partial orders.",
      session: {
        email: "",
        token: "",
      },
      showTooltip: false,
      hasError: false,
      inputFocused: false,
      ifGuid: false,
      isMounted: false,
      orderNoBind: "",
      boxHearderWidth: [
        "width:103px;",
        "width:420px;",
        "width:87px;",
        "width:86px;",
        "width:86px;",
        "width:auto;",
      ],
      boxHearderWidthMobile: [
        "width:90px;",
        "width:144px;",
        "width:70px;",
        "width:70px;",
        "width:70px;",
        "width:70px;",
      ],
    };
  },
  computed: {},
  created: function () {
    console.log("mixin created: guid" + this.guid);

    fetch("/get-session-all")
      .then((response) => response.json())
      .then((data) => {
        console.log("got email and token from remote session.");
        if (data.email) {
          this.session.email = data.email;
          this.session.token = data.token;
          $("#btnLogin").hide();
          $("#btnLogout").show();
        } else {
          $("#btnLogin").show();
          $("#btnLogout").hide();
          sessionStorage.removeItem("lastSearchKeywords");
          //   $("#terms").off('input').on('input', function (e) {
          //         e.target.value = e.target.value.replace(/\D/g, '');
          //     });
        }
      });
  },
  mounted: async function () {
    await this.gtag();
    await this.load();
  },

  methods: {
    navigateToUrl(url) {
      this.$router.push(url);
    },
    getStyleObject(isTrue) {
      return [
        "btn",
        "btn-primary",
        isTrue ? "btnCourierLinkGreen" : "btnCourierLink",
      ];
    },
    getStyleObjectMobile(isTrue) {
      return [
        "btn",
        "btn-primary",
        isTrue ? "btnCourierLinkGreen" : "btnCourierLink-Mobile",
      ];
    },
    getColorStyle(color) {
      let style = "";
      style += "color: #" + color + ";";
      return style;
    },
    scrollLeft(e) {
      let scrollArea = $(e.currentTarget)
        .parent()
        .parent()
        .parent()
        .find(".scrollableArea")[0];

      scrollArea.scrollBy({
        left: -70,
        behavior: "smooth",
      });

    },
    scrollRight(e) {
      let scrollArea = $(e.currentTarget)
        .parent()
        .parent()
        .parent()
        .find(".scrollableArea")[0];

      scrollArea.scrollBy({
        left: 70,
        behavior: "smooth",
      });

    },
    arrowCheck(e) {

      let scrollArea = $(e.currentTarget)
        .parent()
        .find(".scrollableArea")[0];
      let rightArrow = $(e.currentTarget)
        .parent()
        .find(".scrollRight")[0];
      let leftArrow = $(e.currentTarget)
        .parent()
        .find(".scrollLeft")[0];

      if (
        scrollArea.scrollLeft + scrollArea.clientWidth >=
        scrollArea.scrollWidth
      ) {
        rightArrow.style.color = "grey";
        rightArrow.disabled = true;
      } else {
        rightArrow.style.color = "#33363F";
        rightArrow.disabled = false;
      }
      if (scrollArea.scrollLeft == 0) {
        leftArrow.style.color = "grey";
        leftArrow.disabled = true;
      } else {
        leftArrow.style.color = "#33363F";
        leftArrow.disabled = false;
      }
    },
    async gtag() {
      if (
        process.env.VUE_APP_FE_URL.indexOf("ordertracking.officemax.co.nz") !=
        -1
      ) {

        const gtmInlineScript = `
        <!-- Google Tag Manager -->
        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-WP5RV8GT');</script>
        <!-- End Google Tag Manager -->
        `;
        $("head").append(gtmInlineScript); 

        const gtmInlineNoScript = `
        <!-- Google Tag Manager (noscript) -->
        <noscript><iframe src=https://www.googletagmanager.com/ns.html?id=GTM-WP5RV8GT
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
        <!-- End Google Tag Manager (noscript) -->
        `;
        $("body").prepend(gtmInlineNoScript);

        const mouseflowScript = `
        <script type="text/javascript">
          window._mfq = window._mfq || [];
          (function() {
            var mf = document.createElement("script");
            mf.type = "text/javascript"; mf.defer = true;
            mf.src = "//cdn.mouseflow.com/projects/888a9bd0-7d6f-486d-9e59-bfd183f55c71.js";
            document.getElementsByTagName("head")[0].appendChild(mf);
          })();
        </script>      
        `;
        $("body").append(mouseflowScript);

      }
    },
    async load() {
      var url = process.env.VUE_APP_FE_URL;
      console.log("be url:" + url);

      if (!this.isMounted) {
        this.isMounted = true;
        if (this.guid) {
          this.ifGuid = true;
          console.log("this.btnSearchClick(null) invoked!");
          this.btnSearchClick("");
          return;
        }

        console.log("this.ifViewUpdated:" + this.ifViewUpdated);
        console.log("this.lastSearchKeywords:" + this.lastSearchKeywords);

        if (this.ifViewUpdated) {
          if (this.lastSearchKeywords) {
            $("#terms").val(this.lastSearchKeywords);
            console.log(
              "this.btnSearchClick(" + this.lastSearchKeywords + ") invoked!"
            );

            fetch("/get-session-all")
              .then((response) => response.json())
              .then((data) => {
                if (data.email) {
                  this.session.email = data.email;
                  this.session.token = data.token;
                }
                this.btnSearchClick(this.lastSearchKeywords);
                return;
              });
          }
        }
      }
    },
    handleSubmit() {
      console.log("prevent form submit");
    },

    btnLoginClick() {
      console.log("redirect to login");
      window.location.href = window.location.origin + "/azure-login";
    },
    btnLogoutClick() {
      fetch("/clear-session")
        .then((response) => response.json())
        .then((res) => {
          if (res.result == true) {
            sessionStorage.removeItem("lastSearchKeywords");
            this.orderNoBind = "";
            window.location.href = "/";
          }
        });
    },
    formatDateToOct(input) {
      console.log(input);

      if (!input) {
        return "";
      }

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const parts = input.split("/");
      const day = parts[0];
      const month = months[parseInt(parts[1], 10) - 1];
      const year = parts[2];

      return `${day} ${month} ${year}`;

      // return input;
    },
    async btnShareClick() {
      var linkToCopy = $(".btnShare").attr("link");
      await navigator.clipboard.writeText(linkToCopy);
      $(".btnShare a").text("link copied.");

      setTimeout(function () {
        $(".btnShare a").text("Share with customer");
      }, 2000);
    },
    async btnShareMobileClick() {
      var linkToCopy = (linkToCopy = $(".btnShareMobile").attr("link"));
      await navigator.clipboard.writeText(linkToCopy);
      $("#linkCopied").show();

      setTimeout(function () {
        $("#linkCopied").hide();
      }, 2000);
    },
    btnSearchClick: function (ono) {
      this.hasError = false;
      $("#message").hide();
      $(".orderArea").hide();
      $("#right_panel").hide();
      $(".packageInfo").hide();
      $("#footer").hide();
      $(".orderStatus").remove();
      $("#orderDetails").hide();
      $("#overlay").show();

      var orderNo = "";
      if (!this.ifGuid) {
        orderNo = $("#terms").val();
        if (ono.length > 0) {
          orderNo = ono;
          this.orderNoBind = orderNo;
        }
        if (!orderNo) {
          this.showMessage("Order cannot be empty.", "info");
          return;
        }
        if (!orderNo) {
          $("#overlay").hide();
          return;
        }
      }
      var url = process.env.VUE_APP_FE_URL;
      console.log("be url:" + url);

      sessionStorage.setItem("lastSearchKeywords", orderNo);

      let fullUrl = url + "/int/v1.0/order/" + orderNo;
      if (this.ifGuid) {
        fullUrl = url + "/int/v1.0/guid/" + this.guid;
      }

      fetch(fullUrl, {
        headers: {
          Authorization: "Bearer " + this.session.token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.is_error == true) {
            this.showMessage(res.message, "warn");
            this.hasError = true;
            this.customer = {};
            this.orders = [];
            return;
          } else {
            $("#search-alert").hide();
          }

          this.customer = res.CustomerDetail;
          this.customer.shipping_address =
            this.customer.shipping_address.replace(/\\r/g, "<br>");
          this.customer.special_delivery_instruction =
            this.customer.special_delivery_instruction.replace(/\\r/g, "<br>");
          this.customer.orderNo = orderNo;
          this.customer.order_date = VueElement.common.dateConvert(
            this.customer.order_date
          );
          this.orders = res.SalesOrderDetail;
          this.alertInfo = res.crisis_comms;
          this.shipInfo = res.backorders_comms;
        })
        .then(() => {
          if (this.hasError) {
            return;
          }
          var orderSeq = 1;
          for (var i = 0; i < this.orders.length; i++) {
            var order = this.orders[i];
            var status = order.status;
            var package_number = order.package_number;
            if (!order.status_date) {
              order.status_date = ["", "", "", "", "", ""];
            }
            this.statusImgProcess(
              orderSeq,
              status,
              order.status_date,
              package_number
            );
            orderSeq++;
          }
          $("#orderDetails").show();
          $(".orderArea").show();
          $(".packageInfo").show();
          $("#footer").show();
          $("#right_panel").show();
          $("#overlay").hide();
          $(".boxDetailList").css("display", "none");
          $(".productDetailList").css("display", "none");
          $(".btnBoxDetails a").text("View Box Summary");
          $(".btnProductDetails a").text("View Product Summary");

          var toolTipShow = function (event) {
            var tooltip = $(this)
              .parent()
              .parent()
              .parent()
              .parent()
              .find(".tooltip")[0];
            if ($(tooltip).hasClass("tooltip-mobile")) {
              let x = 30;
              let y =
                event.clientY -
                $(this).parent().parent().parent()[0].getBoundingClientRect()
                  .top;
              tooltip.style.left = x + "px";
              tooltip.style.top = y + "px";
              tooltip.style.bottom = "auto";
            } else if ($(tooltip).hasClass("tooltip-tablet")) {
              let x = $(this)[0].getBoundingClientRect().left - 180 - 18;

              tooltip.style.left = x + "px";
            } else if ($(tooltip).hasClass("tooltip-tablet-low")) {
              let x = $(this)[0].getBoundingClientRect().left - 80 - 18;
              tooltip.style.left = x + "px";
              tooltip.style.top = "auto";
              tooltip.style.bottom = "-49px";
            } else {
              let x = $(this)[0].getBoundingClientRect().left - 160 - 18;
              tooltip.style.left = x + "px";
            }

            var content = $(this).parent().attr("data-desc");
            if (content.trim().length > 0) {
              $(tooltip).css("display", "block");
              $(tooltip).find(".tooltipContent").text(content);
            }
          };

          var hideTooltip = function () {
            $(".tooltip").css("display", "none");
          };

          var toolTipBoxNumberShow = function (event) {
            var tooltip = $(this)
              .parent()
              .parent()
              .parent()
              .parent()
              .find(".tooltipBoxNumber")[0];
            if ($(tooltip).hasClass("tooltipBoxNumber-mobile")) {
              let x = 119;
              let y =
                event.clientY -
                $(this).parent().parent().parent()[0].getBoundingClientRect()
                  .top;
              tooltip.style.left = x + "px";
              tooltip.style.top = y + "px";
            } else if ($(tooltip).hasClass("tooltipBoxNumber-tablet")) {
              let x = $(this)[0].getBoundingClientRect().left - 217 - 18;
              tooltip.style.left = x + "px";
            } else if ($(tooltip).hasClass("tooltipBoxNumber-tablet-low")) {
              let x = $(this)[0].getBoundingClientRect().left - 137 - 18;
              tooltip.style.left = x + "px";
              tooltip.style.top = "90px";
              tooltip.style.bottom = "auto";
            } else {
              //   let x = event.clientX - 291;
              let x = $(this)[0].getBoundingClientRect().left - 267 - 18;
              tooltip.style.left = x + "px";
            }

            $(tooltip).css("display", "block");
          };

          $(".imageWrap img").off("click").on("click", toolTipShow);

          $(".imageWrap img").off("mouseenter").on("mouseenter", toolTipShow);

          $(".tooltip")
            .off("click")
            .on("click", function () {
              $(this).css("display", "none");
            });

          $(".imageWrap img").off("mouseout").on("mouseout", hideTooltip);

          $(".red-circle").off("click").on("click", toolTipBoxNumberShow);
          $(".tooltipBoxNumber")
            .off("click")
            .on("click", function () {
              $(this).css("display", "none");
            });
          $(".btnBoxDetails")
            .off("click")
            .on("click", function (event) {
              event.preventDefault();
              let boxList = $(this)
                .parent()
                .parent()
                .parent()
                .parent()
                .find(".boxDetailList");
              if ($(this).hasClass("btnOrder-mobile")) {
                boxList = $(this)
                  .parent()
                  .parent()
                  .parent()
                  .find(".boxDetailList");
              }
              if (boxList.css("display") == "none") {
                boxList.css("display", "block");
                $(this).find("a").text("Hide Box Summary");
              } else {
                boxList.css("display", "none");
                $(this).find("a").text("View Box Summary");
              }
              let productList = $(this)
                .parent()
                .parent()
                .parent()
                .parent()
                .find(".productDetailList");
              if (productList.css("display") == "block") {
                productList.css("display", "none");
                $(this)
                  .parent()
                  .find(".btnProductDetails a")
                  .text("View Product Summary");
              }
            });
          $(".btnProductDetails")
            .off("click")
            .on("click", function (event) {
              event.preventDefault();
              let productList = $(this)
                .parent()
                .parent()
                .parent()
                .parent()
                .find(".productDetailList");
              if ($(this).hasClass("btnOrder-mobile")) {
                productList = $(this)
                  .parent()
                  .parent()
                  .parent()
                  .find(".productDetailList");
              }
              if (productList.css("display") == "none") {
                productList.css("display", "block");
                $(this).find("a").text("Hide Product Summary");
              } else {
                productList.css("display", "none");
                $(this).find("a").text("View Product Summary");
              }
              let boxList = $(this)
                .parent()
                .parent()
                .parent()
                .parent()
                .find(".boxDetailList");
              if (boxList.css("display") == "block") {
                boxList.css("display", "none");
                $(this)
                  .parent()
                  .find(".btnBoxDetails a")
                  .text("View Box Summary");
              }
            });

          //   $(".btnOrderEmpty")
          //     .off("click")
          //     .on("click", function () {

          //     });
          //   $(".btnOrderEmpty-mobile")
          //     .off("click")
          //     .on("click", function () {});
        });
    },
    btnOrderEmptyClick: function (e) {
      let orderNo = e.currentTarget.getAttribute("data-orderno");
      let prefix = e.currentTarget.getAttribute("data-prefix");
      var url = process.env.VUE_APP_FE_URL;
      let fullUrl = url + "/int/v1.0/send_delivery_paperwork/" + orderNo;
      if (prefix) {
        fullUrl = fullUrl + "/" + prefix;
      }
      fetch(fullUrl, {
        headers: {
          Authorization: "Bearer " + this.session.token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.is_error == true) {
            this.showMessage(res.message, "warn");
            this.hasError = true;
          } else {
            this.showMessage(res.message, "info");
          }
          document
            .getElementById("search-alert")
            .scrollIntoView({ behavior: "smooth", block: "nearest" });
        });
    },
    showMessage(message, level) {
      $("#search-alert p").html(message);
      $("#search-alert").removeClass("alert-warn");
      $("#search-alert").removeClass("alert-info");
      if (level == "warn") {
        $("#search-alert").addClass("alert-warn");
      } else {
        $("#search-alert").addClass("alert-info");
      }

      $("#search-alert").show();
      $("#overlay").hide();
    },
    statusImgProcess(orderSeq, status, status_date, package_number) {
      for (var i = 0; i < this.statusNameMap.length; i++) {
        var item = this.statusNameMap[i];

        $(".order" + orderSeq + " .status" + (i + 1)).attr(
          "src",
          "../images/" + item.img + ".png"
        );
        if (status_date[i]) {
          $($(".order" + orderSeq + " .imgDate")[i]).text(status_date[i]);
        }
        if (package_number[i] > 0) {
          $($(".order" + orderSeq + " .packageNo span")[i]).text(
            package_number[i]
          );
          $($(".order" + orderSeq + " .packageNo span")[i]).addClass(
            "red-circle"
          );

          $($(".order" + orderSeq + " .packageNo-mobile span")[i]).text(
            package_number[i]
          );
          $($(".order" + orderSeq + " .packageNo-mobile span")[i]).addClass(
            "red-circle"
          );
        } else {
          $($(".order" + orderSeq + " .packageNo span")[i]).text("");
          $($(".order" + orderSeq + " .packageNo span")[i]).removeClass(
            "red-circle"
          );

          $($(".order" + orderSeq + " .packageNo-mobile span")[i]).text("");
          $($(".order" + orderSeq + " .packageNo-mobile span")[i]).removeClass(
            "red-circle"
          );
        }
      }
      for (i = 0; i < status.length; i++) {
        if (status[i] == 0) {
          $($(".order" + orderSeq + " .imageWrap")[i]).css(
            "border-color",
            "#FDB813"
          );
          $($(".order" + orderSeq + " .line1")[i - 1]).css(
            "border-color",
            "#FDB813"
          );
          $($(".order" + orderSeq + " .line1")[i - 1]).css(
            "border-bottom-style",
            "dashed"
          );
          $($(".order" + orderSeq + " .line1")[i - 1])
            .parent()
            .css("padding-left", "5px");
          // $($('.order'+orderSeq+' .imageWrap')[i]).css('margin-left','5px');
          // $($('.order'+orderSeq+' .line1')[i-1]).css('width','95%');

          $($(".order" + orderSeq + " .imageWrap-mobile")[i]).css(
            "border-color",
            "#FDB813"
          );
          $($(".order" + orderSeq + " .line1-mobile")[i - 1]).css(
            "border-color",
            "#FDB813"
          );
          $($(".order" + orderSeq + " .line1-mobile")[i - 1]).css(
            "border-left-style",
            "dashed"
          );
        }
        if (status[i] == 1) {
          $($(".order" + orderSeq + " .imageWrap")[i]).css(
            "border-color",
            "green"
          );
          $($(".order" + orderSeq + " .line1")[i - 1]).css(
            "border-color",
            "green"
          );
          $($(".order" + orderSeq + " .line1")[i - 1]).css(
            "border-bottom-style",
            "solid"
          );

          $(".order" + orderSeq + " .status" + (i + 1)).attr(
            "src",
            "../images/Tick-Green-Icon 1.png"
          );
          if (i <= 2) {
            $(".order" + orderSeq + " .status" + (i + 1))
              .parent()
              .css("border", "2px solid white");
          }
          if (i > 2) {
            $(".order" + orderSeq + " .status" + (i + 1))
              .parent()
              .css("border", "2px solid #BD844C 0.1");
            $($(".order" + orderSeq + " .imageWrap")[i]).css(
              "border-color",
              "rgb(189, 132, 76,0)"
            );
          }
        }
      }
    },
  },
};
