<template>
    <component :is="currentView" :guid="guid" :lastSearchKeywords="lastSearchKeywords" :ifViewUpdated="ifViewUpdated"></component>
  </template>
  
  <script>
  import MobileView from './FAQMobileView.vue';
  import TabletView from './FAQTabletView.vue';
  import DesktopView from './FAQDesktopView.vue';
  import TabletLowView from './FAQTabletLowView.vue';
  import { useRoute } from 'vue-router'
  
  export default {
    setup() {
      const route = useRoute();
      const guid = route.params.guid;
      console.log('home setup:guid'+guid);
      return { guid };      
    },
    data() {
      return {
        currentView: '',
        lastSearchKeywords: '', 
        ifViewUpdated:false,
      };
    },
    components: {
      MobileView,
      TabletView,
      DesktopView,
      TabletLowView,
    },
    mounted() {
      this.updateView();
      window.addEventListener('resize', this.updateView);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.updateView);
    },
    methods: {
      updateView() {
        const width = window.innerWidth;
  
        if (width < 440) {
          this.currentView = 'MobileView';
        } else if (width >= 440 && width <= 950) {
          this.currentView = 'TabletLowView';
        } else if (width >= 950 && width <= 1440) {
          this.currentView = 'TabletView';
        }  else {
          this.currentView = 'DesktopView';
        }
        this.ifViewUpdated = true;
      },
     
  
    }
  };
  </script>
  